<template>
   <div class="banner-wrapper">
        <div class="banner-cover"></div>
        <img class="banner-video" src="@/assets/img/about_banner.png">
        <div class="banner-title container scroll-from-top">
            <h1 >About Us</h1>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
           
         window.sr = ScrollReveal();
	    this.$nextTick(() => {
      		sr.reveal('.scroll-from-left', {
				origin: 'left' ,
				duration: 1000,
				easing: 'ease-out' ,
				distance: '500px',
				delay: 100,
				         
			});
			    sr.reveal('.scroll-from-right', {
                    origin: 'right' ,
                    duration: 1000,
                    easing: 'ease-out' ,
                    distance: '200px',
                    delay: 100     ,
					     
                });
                sr.reveal('.scroll-from-bottom', {    
                    duration: 1000,
                    distance: '50px',
                    delay: 100              
                });
                sr.reveal('.scroll-from-top', {    
                    origin: 'top' ,
                    duration: 1000,
                    distance: '200px',
                    delay: 100              
                });
				sr.reveal('.scroll-from-opacity', {    
                       distance: '200px',
						opacity: 0.8,
						duration: 1000,
                });
				sr.reveal('.scroll-from-scaleUp', {    
                       distance: '200px',
   				scale: 0.85,
                duration: 1000,
                });
      	    });
    }
}
</script>

<style>

.banner-wrapper{
    height: 35rem;
    background-color: aliceblue;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.banner-cover{
    width: 100%;
    height: 100%;
    background-color: #00000054;
    position: absolute;
}
.banner-title{
    position: absolute;
    color: white;
}
.banner-title h1{
    margin: 0;
    font-size: 3.125rem;
}
@media (max-width: 768px) {
    .banner-wrapper{
        height: 10rem;
    }
    .banner-title{
        padding-left: 1rem;
        box-sizing: border-box;
    }
    .banner-title h1{
        font-size: 1.125rem;
    }
}
</style>