<template>
    <div>
        <banner></banner>  
         <div class="home-feature__wrapper">
            <div class="container">
                <div class="about-background__wrapper scroll-from-bottom">
                    <h1 class="home-feature__card-title">Company Background</h1>
                    <div>
                        <p class="text-justify">In 2015, Shui Xing Design and Build Sdn Bhd (SXDB) has established to accommodate SX Group Of Companies’ business diversify and expansion on the construction sector, which its main focus is to provide warehouse design and build for its potential domestics and international customers. <span v-if="isMobile" class="about-more" @click="isShowMoreParagraph = !isShowMoreParagraph">more...</span> </p>
                        <div v-if="!isMobile">
                            <p class="text-justify">The company quickly expanded with multiple construction projects within PKFZ (Port Klang Free Zone, Malaysia), in Port Klang, and Johor Bahru, Johor. With a dedicated and hands on Management Team, the company grew by leaps and bounds over the past few years. The company is constantly engaging expert construction professionals with diverse backgrounds and formed a team that would provide high and dedicated services to its customers.</p>
                            
                            <p class="text-justify">As part of our quality services, SXDB helps client’s designs, build and logistics through a network of facilities. This provides our clients with complete designs, engineering and material supply resources that are vertically integrated with Engineering solutions to optimize their operation by lowering their costs and reducing their time to market.</p>
                            
                            <p class="text-justify">Being part of the SX Group, we strongly believe in providing the best services to our valued customers, thus, for customers who engaged SXDB to design and build their warehouses, SXDB also assists its customers to facilitate a ‘lease back’ engagement with our associated companies within the Group with lease back option to our customers, where we lease the built warehouse from our customers and operate the warehouses. This provides a win-win solution to our customers and enable our customers to have more cost-effective investment, as the rental of their warehouse is secured by SXDB for initial periods upon completion.</p>
                        </div>
                        <div v-if="isMobile && isShowMoreParagraph">
                            <p class="text-justify">The company quickly expanded with multiple construction projects within PKFZ (Port Klang Free Zone, Malaysia), in Port Klang, and Johor Bahru, Johor. With a dedicated and hands on Management Team, the company grew by leaps and bounds over the past few years. The company is constantly engaging expert construction professionals with diverse backgrounds and formed a team that would provide high and dedicated services to its customers.</p>
                            
                            <p class="text-justify">As part of our quality services, SXDB helps client’s designs, build and logistics through a network of facilities. This provides our clients with complete designs, engineering and material supply resources that are vertically integrated with Engineering solutions to optimize their operation by lowering their costs and reducing their time to market.</p>
                            
                            <p class="text-justify">Being part of the SX Group, we strongly believe in providing the best services to our valued customers, thus, for customers who engaged SXDB to design and build their warehouses, SXDB also assists its customers to facilitate a ‘lease back’ engagement with our associated companies within the Group with lease back option to our customers, where we lease the built warehouse from our customers and operate the warehouses. This provides a win-win solution to our customers and enable our customers to have more cost-effective investment, as the rental of their warehouse is secured by SXDB for initial periods upon completion.</p>
                        </div>
                    </div>
                </div>
                <div class="home-feature__card">
                    <div class="home-feature__card-img__wrapper scroll-from-left">
                        <img class="w-100" src="@/assets/img/about_1.png" alt="Shuixing design and build">
                    </div>
                    <div class="home-feature__card-text__wrapper scroll-from-right">
                        <h1 class="home-feature__card-title">Our Vision</h1>
                        <p class="home-feature__card-text">We provide professional client-focused construction solutions. As an employee-owned company, we go above and beyond on every project and deliver on our promises with integrity.</p>
                    </div>
                </div>
                <div class="home-feature__card fl-cr">
                    <div class="home-feature__card-text__wrapper scroll-from-left">
                        <h1 class="home-feature__card-title">Our Mission</h1>
                        <p class="home-feature__card-text">We aspire to be your partner for design and build.  We are dedicated to continuously delivering superior service quality standards, timely completion, customer satisfaction and affordability constructions. </p>
                    </div>
                    <div class="home-feature__card-img__wrapper scroll-from-right">
                        <img class="w-100" src="@/assets/img/about_2.png" alt="Shuixing design and build">
                    </div>
                </div>
                <div class="about-team__wrapper">
                      <h1 class="home-feature__card-title about-team__title">Our Team</h1>
                      <div class="og-chart__wrapper">
                        <img src="@/assets/img/org_chart.png">
                      </div>
                </div>
            </div>
          </div>
            <section class="about-cert__wrapper">
                <div class="container about-cert__container">
                    <div class="about-cert__text-wrapper">
                        <h2>Certificates</h2>
                        <p class="about-cert__desc">We provide professional client-focused construction solutions. As an employee-owned company, we go above and beyond on every project and deliver on our promises with integrity.</p>
                    </div>

                    <a href="/cert.pdf" target="_blank" download class="about-team__cert-wrapper">
                        <img src="@/assets/img/certificate.png">
                    </a>
                </div>
            </section>
            <section class="about-client__wrapper">
                <div class="container">
                    <h2 class="home-feature__card-title tc">Clients We Work With</h2>
                    <div class="about-client__logo-wrapper">
                        <img :src="require(`@/assets/img/${o.src}.png`)" :class="`img-${index}`" v-for="(o, index) in clientList" :key="index">
                    </div>
                </div>
            </section>
      
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Banner from '@/components/BannerAbout.vue'
export default {
    components: {
        Banner
    },
    data() {
        return {
            isShowMoreParagraph: false,

            clientList: [
                {
                    src: 'client_1'
                },
                {
                    src: 'client_2'
                },
                {
                    src: 'client_3'
                }
            ]
        }
    },
    mounted() {
        
         window.sr = ScrollReveal();
	    this.$nextTick(() => {
      		sr.reveal('.scroll-from-left', {
				origin: 'left' ,
				duration: 1000,
				easing: 'ease-out' ,
				distance: '500px',
				delay: 100,
				         
			});
			    sr.reveal('.scroll-from-right', {
                    origin: 'right' ,
                    duration: 1000,
                    easing: 'ease-out' ,
                    distance: '200px',
                    delay: 100     ,
					     
                });
                sr.reveal('.scroll-from-bottom', {    
                    duration: 1000,
                    distance: '50px',
                    delay: 100              
                });
                sr.reveal('.scroll-from-top', {    
                    origin: 'top' ,
                    duration: 1000,
                    distance: '200px',
                    delay: 100              
                });
				sr.reveal('.scroll-from-opacity', {    
                       distance: '200px',
						opacity: 0.8,
						duration: 1000,
                });
				sr.reveal('.scroll-from-scaleUp', {    
                       distance: '200px',
   				scale: 0.85,
                duration: 1000,
                });
      	    });
    },

    computed:{
		...mapGetters([              
			'isMobile',
		]),
	},
}
</script>

<style>
.about-background__wrapper{
    margin-bottom: 6.25rem;
}
.about-team__wrapper{
     margin-bottom: 10.25rem;
}

/* client */
.about-client__wrapper{
    background-color: #E8E7E7;
    padding: 5rem 0;
}
.about-client__logo-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.about-client__logo-wrapper img{
    max-width: 431px;
}
/* cert */
.about-cert__wrapper{
    background-color: #8E6047;
    height: 18.4375rem;
}
.about-cert__container{
    display: flex;
    position: relative;
    align-items: center;
    color: white;
    height: inherit;
}
.about-cert__text-wrapper{
    width: 48.21875rem;
    display: flex;
    grid-column-gap: 3rem;
}
.about-cert__text-wrapper h2{
    font-size: 2.125rem;
}
.about-team__cert-wrapper{
    position: absolute;
    right: 0;
    bottom: 1rem;
}

@media (max-width: 768px) {
    .og-chart__wrapper{
        overflow-x: scroll;
    }
    .about-background__wrapper{
        margin-bottom: 2rem;
    }
    .about-cert__text-wrapper{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .about-more{
        color: var(--color-theme);
        text-decoration: underline;
    }
    .about-cert__desc{
        text-align: center;
        padding: 0 1rem;
    }
    .about-cert__container{
        flex-direction: column;
    }
    .about-team__cert-wrapper{
        position: relative;
        bottom: auto;
    }
    .about-team__title{
        text-align: center;
    }
    .about-client__logo-wrapper{
        flex-direction: column;
    }
    .about-client__logo-wrapper img{
        object-fit: contain;
        width: 100%;
        height: 3rem;
        margin-bottom: 1rem;
        max-width: 100%;
    }
    .about-client__logo-wrapper .img-2{
        height: 7rem;
    }   
    .about-team__wrapper{
        margin-bottom: .25rem;
    }
    .about-client__wrapper{
        margin-top: 20rem;
        padding: 2rem 0;
    }
}

</style>